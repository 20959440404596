<template>
    <v-container fluid>
        <router-view name="notifications"></router-view>
        <transition name="fadeonly" mode="out-in">
            <router-view></router-view>
        </transition>
    </v-container>
</template>

<script>
export default {}
</script>
